<template>
  <div id="tracking">
    <v-flex xs12 class="box">
      <span class="title"> {{ $t('order.tracking') }} </span>
      <v-btn icon :color="bgColor" class="pull-right" @click="close()">
        <v-icon medium>mdi-close</v-icon>
      </v-btn>

      <v-divider class="mt-3"></v-divider>

      <div class="mt-3 mb-3" v-if="data">
        <v-layout wrap class="mb-3"> 
          <v-flex xs12 sm12 md12>
            <v-timeline class="mb-0 mt-0 pt-0"
              dense
              clipped
            >
              <v-timeline-item v-for="(item, index) in data" :key="index"
                icon-color="grey lighten-2"
                small
              >
                <v-row justify="space-between">
                  <v-col cols="12" class="text">
                    {{ item.keterangan }}
                  </v-col>
                  <v-col cols="12" class="text">
                    {{ formatDateTime(item.waktu) }}
                  </v-col>
                </v-row>
              </v-timeline-item>
            </v-timeline>
          </v-flex>
        </v-layout>
      </div>
    </v-flex>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import axios from '@/axios'

  export default {
    name: 'TrackingOrder',
    props: {
      no_resi: {
        type: String,
        default: ''
      }
    },
    computed: {
      ...mapState([
        'isMobile',
        'token',
        'bgColor'
      ])
    },
    data () {
      return {
        data: null
      }
    },
    methods: {
      close () {
        this.$emit('close')
      },
      getTrackingOrder (no_resi) {
        this.$store.commit('setIsLoading', true)

        axios.defaults.headers = {
          id_brand: process.env.VUE_APP_BRAND,
          token: this.token,
          is_offline: true
        }

        axios.get('/api/v1/member/tracking/' + no_resi)
          .then(response => {
            this.data = response.data.data
          })
          .catch(error => {
            this.errorHandling(error)
          })
          .finally(() => {
            this.$store.commit('setIsLoading', false)
          })
      }
    }, 
    mounted () {
      this.getTrackingOrder(this.no_resi)
    }
  }
</script>

<style scoped>
  .box {
    background-color: #FFFFFF;
    color: #000000;
    padding: 20px;
    margin-bottom: 2px;
    font-size: 0.8rem !important;
    font-style: normal !important;
  }

  .title {
    color: #000000;
    font-weight: 900;
    font-size: 0.9rem !important;
  }

  .text {
    color: #000000;
    margin-bottom: 2px;
    padding: 0;
    font-size: 0.8rem !important;
    font-style: normal !important;
  }

  .pull-right {
    float: right;
  }
</style>
